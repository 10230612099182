export const config = {

  //local development
  // api: 'https://d1da9946424a.in.ngrok.io/api/',
  // layerSocket: 'https://d1da9946424a.in.ngrok.io/api/layerSocket',
  // driversocket: 'https://d1da9946424a.in.ngrok.io/api/driver_uid',
  // chickensocket: 'https://d1da9946424a.in.ngrok.io/api/ccuid_uid',
  // socketURL: 'https://d1da9946424a.in.ngrok.io/api/socketConn',
  // api: 'http://8381-183-82-107-114.ngrok.io/api/',

  //*****localhost*****//
  // api: 'http://localhost:3000/api/',
  // layerSocket: 'http://localhost:3000/api/layerSocket',
  // driversocket: 'http://localhost:3000/api/driver_uid',
  // chickensocket: 'http://localhost:3000/api/ccuid_uid',
  // socketURL: 'http://localhost:3000/api/socketConn',

  //*****backupserver*****//
  // api: 'https://backoffice.poultryapp.com/backup_api/',
  // driversocket: 'https://backoffice.poultryapp.com/backup_api/driver_uid',
  // chickensocket: 'https://backoffice.poultryapp.com/backup_api/ccuid_uid',
  // socketURL: 'https://backoffice.poultryapp.com/backup_api/socketConn',
  // layerSocket: 'https://backoffice.poultryapp.com/backup_api/layerSocket',

  //****production urls *****//
  api: 'https://backoffice.poultryapp.com/api/',
  driversocket: 'https://backoffice.poultryapp.com/api/driver_uid',
  chickensocket: 'https://backoffice.poultryapp.com/api/ccuid_uid',
  socketURL: 'https://backoffice.poultryapp.com/api/socketConn',
  layerSocket: 'https://backoffice.poultryapp.com/api/layerSocket',
  routerxl: 'http://api.routexl.com/',

  type: "app", //"device"
  webPort: 81,

  endPoint: "https://backoffice.poultryapp.com/api",
  socketEndPointEc: "'http://localhost:3000/ecAutomationSocket'",

  iotEndPoint: "https://backoffice.poultryapp.com/iot",
  socketEndPointIot: "https://backoffice.poultryapp.com",

  // iotEndPoint: "http://localhost:2700/iot",
  // socketEndPointIot: "http://localhost:2700",


  googleApiKey: "AIzaSyB-EbCaPeaG2lxYOhNNHhruzGO2IKL8vtg",
  googleApiKeyPrevious: "AIzaSyBwcZLpNXEqa8DlG3DogW_GxECxMb0-qok",
};
