import { CodePush } from '@ionic-native/code-push/ngx';
import { TraderOrderShowSortPopupComponent } from "./trader-order-show-sort-popup/trader-order-show-sort-popup.component";
import { AddTraderSortPopupComponent } from "./add-trader-sort-popup/add-trader-sort-popup.component";
import { NgModule } from "@angular/core";
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { BrowserModule } from "@angular/platform-browser";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { OtpPageModule } from "../app/otp/otp.module"
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { CommonExportsModule } from './common-exports/common-exports.module';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { InterceptorService } from '../services/interceptor.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Network } from '@ionic-native/network/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as ChartData from 'highcharts/modules/data.src';
import { AlertController, ToastController } from '@ionic/angular';
import { SendMesComponent } from './send-mes/send-mes.component';
import { ChickenPopoverComponent } from './chicken-popover/chicken-popover.component'
import { LoginService } from './login/login.service';
import { TraderSortingComponent } from './trader-sorting/trader-sorting.component';
import { NamePipe } from './pipes/name.pipe';
import { PriceByAscPipe } from './pipes/price-by-asc.pipe';
import { PriceByDescPipe } from './pipes/price-by-desc.pipe';
import { LiftingsPopupComponent } from './liftings-popup/liftings-popup.component';
import { ReviewDeleteComponent } from './ricehusk-review/review-delete/review-delete.component'
import { AuthGuardService } from './authentication.guard';
import { AuthServicesService } from './auth-services.service';
import { ProfileService } from './profile/profile.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { File } from '@ionic-native/file/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { MenuopenDirective } from './menuopen.directive';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { SortPopoverComponent } from "./trader/sort-popover/sort-popover.component";
import { LeasePageModule } from "./lease/lease/lease.module";
import { MyfarmPageModule } from './home/myfarm/myfarm.module';
import { InventoryPageModule } from './home/inventory/inventory.module';
import { TraderOrderShowPageModule } from "../app/trader-order-show/trader-order-show.module";
import { ChickenCenterOrderConformationComponent } from './chicken-center-order-conformation/chicken-center-order-conformation.component';

import { CommonServicesModule } from './common-exports/common-services.module';
import { InventoryCreateComponent } from '../app/home/inventory-create/inventory-create.component';
import { FlashMessageService } from "./flash-message.service";
import { CustmmessagePopOverComponent } from './custmmessage-pop-over/custmmessage-pop-over.component';
import { ChickenCenterOrderinfoComponent } from './chicken-center-home/chicken-center-orderinfo/chicken-center-orderinfo.component';
import { LiftingsPriceSettingComponent } from "./liftings-price-setting/liftings-price-setting.component";
import { InstantNotificationsComponent } from './instant-notifications/instant-notifications.component';
import { NetworkService } from './services/network.service';
import { SupervisorfarmdetailPipe } from './supervisorfarmdetail.pipe';
import { LayersSalesPriceSettingsComponent } from "./layers-sales-price-settings/layers-sales-price-settings.component";
import { HttpLoaderFactory, SharedModule } from './shared.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from "@angular/router";
import { TranslateModule, TranslateLoader, TranslateStore } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateConfigService } from '../app/services/translate-config.service';
import { MissingTranslationService } from './services/missing-translation.service';
import { SelectVanComponent } from './layer-wholeSeller/select-van/select-van.component';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LayerFarmerAutoOrderDetailsComponent } from "./layer-farmer-auto-order-details/layer-farmer-auto-order-details.component";
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ContactAccessDetailsPage } from './linked-contacts/contact-access-details/contact-access-details.page';

import { LiftingvanDetailsPage } from './trader/liftingvan-details/liftingvan-details.page';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SqlDbseriveService } from "./Dbservices/sql-dbserive.service";
import { NumberFormatPipe } from './pipes/number-format.pipe';


// import { BatteryStatus } from '@ionic-native/battery-status/ngx';
// import { BackgroundFetch, BackgroundFetchConfig } from '@ionic-native/background-fetch/ngx';
// import { BackgroundGeolocation } from "@ionic-native/background-geolocation/ngx";
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';
// import { NativeAudio } from '@ionic-native/native-audio/ngx';
// import { OrderPipe } from '../app/pipes/order.pipe';
// import { OrdersortPipe } from './pipes/ordersort.pipe';
// import { AgmCoreModule } from '@agm/core';
// import { AgmDirectionModule } from 'agm-direction';
// import { AddTraderSortPopupComponent } from "./add-trader-sort-popup/add-trader-sort-popup.component";
// import { CreateOrderPage } from '../app/create-order/create-order.page';
// import { ChickenCenterHomePageModule } from './chicken-center-home/chicken-center-home.module';
// import { Facebook } from '@ionic-native/facebook/ngx';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
// import { TimerPipePipe } from './pipes/timer-pipe.pipe';
// import { MyfarmCreateEditComponent } from "./myfarm-create-edit/myfarm-create-edit.component";
// import { GoogleMaps } from '@ionic-native/google-maps/ngx';
// import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
// import { SQLite } from '@ionic-native/sqlite/ngx';
// import { CreateOrderPage } from '../app/create-order/create-order.page';
// import { SuccessPopupComponent } from './success-popup/success-popup.component';
@NgModule({
  declarations: [
    AppComponent,
    SendMesComponent,
    ChickenPopoverComponent,
    TraderSortingComponent,
    TraderOrderShowSortPopupComponent,
    NamePipe,
    PriceByAscPipe,
    ChickenCenterOrderConformationComponent,
    PriceByDescPipe,
    LiftingsPopupComponent,
    ReviewDeleteComponent,
    MenuopenDirective,
    SortPopoverComponent,
    AddTraderSortPopupComponent,
    InventoryCreateComponent,
    CustmmessagePopOverComponent,
    ChickenCenterOrderinfoComponent,
    LiftingsPriceSettingComponent,
    LayersSalesPriceSettingsComponent,
    InstantNotificationsComponent,
    SelectVanComponent,
    SupervisorfarmdetailPipe,
    LayerFarmerAutoOrderDetailsComponent,
    ContactAccessDetailsPage,
    NumberFormatPipe,
    // LiftingvanDetailsPage
    // TimerPipePipe
    // LayerGraphDirective,
    // OrderPipe,
    // OrdersortPipe,
    // SuccessPopupComponent,
    // ChickenHomePageComponent ,
    // CreateOrderPage,
    // MyfarmCreateEditComponent,
    // SuccessPopupComponent,
  ],
  entryComponents: [
    SendMesComponent,
    TraderSortingComponent,
    TraderOrderShowSortPopupComponent,
    AddTraderSortPopupComponent,
    ReviewDeleteComponent,
    ChickenPopoverComponent,
    SortPopoverComponent,
    LiftingsPopupComponent,
    ChickenCenterOrderConformationComponent,
    CustmmessagePopOverComponent,
    InventoryCreateComponent,
    ChickenCenterOrderinfoComponent,
    LiftingsPriceSettingComponent,
    LayersSalesPriceSettingsComponent,
    InstantNotificationsComponent,
    SelectVanComponent,
    LayerFarmerAutoOrderDetailsComponent,
    //  MyfarmCreateEditComponent,
    // InventorieslistComponent
    // CreateOrderPage,
  ],
  // ChickenHomePageComponent
  // ],
  // entryComponents: [SendMesComponent, 
  //  TraderSortingComponent,CreateOrderPage,
  //   TraderOrderShowSortPopupComponent,
  //   AddTraderSortPopupComponent,LiftingsPopupComponent, ReviewDeleteComponent, ChickenPopoverComponent, SortPopoverComponent],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    ChartModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    OtpPageModule,
    ReactiveFormsModule,
    CommonExportsModule,
    IonicModule.forRoot({
      backButtonText: '' // empty string to have no text next to the back icon globally
    }),
    TraderOrderShowPageModule,
    InventoryPageModule,
    MyfarmPageModule,
    LeasePageModule,
    CommonServicesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    DragDropModule
    // IonicModule.forRoot(),
  ],
  providers: [
    FirebaseX, Keyboard, AppVersion, CodePush,
    StatusBar,
    AuthGuardService,
    AuthServicesService,
    ProfileService,
    AlertController,
    LoginService,
    FlashMessageService,
    ToastController,
    SocialSharing,
    DocumentViewer,
    File,
    FileOpener,
    LocationAccuracy,
    Diagnostic,
    Network,
    FileTransfer,
    SplashScreen,
    Camera,
    Geolocation,
    NativeGeocoder,
    NetworkService,
    TranslateConfigService,
    TranslateStore,
    NumberFormatPipe,
    // BatteryStatus,
    // BackgroundFetch,
    // BackgroundMode,
    // BackgroundGeolocation,
    // NativeAudio,
    // GoogleMaps,
    // SQLitePorter,
    // SQLite,
    // SqlDbseriveService,
    // Facebook,
    // GooglePlus,
    { provide: MissingTranslationService, useClass: MissingTranslationService },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ChartData] },
    CallNumber,
    AndroidPermissions,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})

export class AppModule { }
